/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    em: "em",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta, SideBySide} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/unitron/'], ['en', 'https://www.hear.com/hearing-aids/brands/unitron/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/unitron/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/unitron/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " should be as advanced as you are, which is why Unitron is continuously pushing the limits of hearing aid technology. Unitron puts the wearer at the center of their design process, focusing on what they can do to improve the individual’s day to day life. Here, we’ll take an in-depth look at how Unitron’s hearing aids have developed throughout their history and what you can expect from their current offerings."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-half-century-of-innovation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-half-century-of-innovation",
    "aria-label": "a half century of innovation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Half-Century of Innovation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron has its roots in, of all things, an electronics and television business run by three German immigrants living in Newfoundland, Canada in 1964. The three men, Fred Stork, Rolf Strothmann, and Rolf Dohmer, had, until that point, focused their efforts on tv and radio sales but soon saw hearing aids as a potential business expansion opportunity."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Since the hearing aid industry was a new one at the time, the trio set to work building their own hearing aids through trial and error, showing their new devices at trade shows, and working to improve their designs. Eventually, after a few years, they moved the company to Ontario, and the rest, as they say, is history."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These days, Unitron continues to make waves in the hearing aid industry, leading the way through the digitization of hearing aids in the 90s, when they developed the first digital chip – the Toccata chip. With the introduction of digital technology, Unitron has continued to push the limits of what’s possible in terms of innovation and functionality in hearing aids. Although they started small, Unitron is now a global leader in the hearing aid industry."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "revolutionizing-your-listening-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#revolutionizing-your-listening-experience",
    "aria-label": "revolutionizing your listening experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Revolutionizing Your Listening Experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As one of the leaders in the digitization of hearing aids in the 1990s, it’s no surprise that Unitron continues to lead the way in innovation in the digital age. These days, Unitron hearing aids are chock full of technology that’s designed to give wearers the most natural and comfortable listening experience. Here are some of the technologies that you’ll find in a Unitron hearing aid:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Stay Up-To-Date With SpeechPro and SpeechZone")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron’s SpeechPro technology helps hearing aid wearers pick out speech in noise for a better grasp of a conversation. The technology helps the individual pick out where the sound is coming from for a more natural conversation, even in loud, challenging environments. Plus, with SpeechZone, listeners can zone in on speech even in a busy environment without ever having to adjust their hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Spatial Awareness Technology")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Unitron’s new spatial awareness technology, wearers can feel comfortable and confident in their surroundings. This technology helps the individual quickly and accurately identify the direction of speech and where sounds are coming from through localization in space."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Log It All for Better Insight")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron knows that they can collect data about your listening experiences to help improve it in the future, which is why they use “Log It All’ technology to capture your listening data in context. This data can be used by your hearing healthcare provider to help you fine-tune your hearing aids using evidence-guided decision making for a better listening experience in the long term."), "\n", React.createElement(LandingPageCta, {
    copy: "Try Unitron hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/unitron-discover.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "unitron-discover",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-discover",
    "aria-label": "unitron discover permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron Discover"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Unitron Discover platform introduces updates to their sophisticated digital processing system for hearing aids. You may experience this platform in either the Moxi Jump R or the Moxi Fit models. Both of these hearing aids are small, discreet receiver-in-the-ear models available in a variety of fashionable colors. The Jump R features a rechargeable battery; the Fit utilizes a size 312 battery."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/discover/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/unitron-insera.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "unitron-insera",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-insera",
    "aria-label": "unitron insera permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron Insera"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "You ears have a distinctive shape that some scientists have researched as a possible substitute for fingerprints in identification. Likewise, your hearing loss and the challenges you face are unique to you. Therefore, you deserve to work with a hearing care professional who will customize a hearing technology solution for you."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/insera/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/unitron-max.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "unitron-max",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-max",
    "aria-label": "unitron max permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron Max"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Smart engineers at Unitron designed the Max hearing aid for the demanding lifestyles of people with severe to profound hearing loss. Two models, the Max SP and the Max UP, offer different levels of power to match your hearing loss."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/max/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/unitron-stride.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "unitron-stride",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-stride",
    "aria-label": "unitron stride permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron Stride"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The SoundNav on board Unitron Stride hearing aids classifies sound into up to seven listening environments. Once the listening situation has been identified, the hearing aids shift their processing into the mode that best fits the situation. If you want more control, use the phone app or the button on the hearing aids to tweak your settings."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/stride/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/unitron-moxi.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "unitron-moxi",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-moxi",
    "aria-label": "unitron moxi permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron Moxi"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you ever been on an important call yet unable to hear the person on the other line? This would be frustrating for you AND for the recipient of your call. If it’s a business call, this negative interaction could totally ruin future work opportunities, or if it’s a personal call, it could seriously damage a meaningful relationship. You need to be as secure in your phone etiquette as you are in your day-to-day interactions. With Unitron’s Moxi hearing aid, hearing loss doesn’t have to inhibit your ability to communicate in an increasingly digital society while not compromising on in-person quality."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/moxi/",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
